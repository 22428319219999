body.internal {
    p {
        line-height  : 1.35;
        margin-bottom: 3rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .header {
        position: relative;

        .logo {
            background-image: url('/2020/images/logo-black.png');
        }

        .menu {
            color: #000;
        }
    }


    .top-line {
        height     : 5rem;
        color      : #fff;
        text-align : center;
        padding-top: .3rem;

        span {
            vertical-align: sub;
        }
    }

    .arrow-before {
        position: relative;

        &:before {
            content          : '';
            background-repeat: no-repeat;
            background-size  : contain;
            position         : absolute;

        }

        &.style-0 {
            &:before {
                height          : 7rem;
                width           : 3.3rem;
                background-image: url('/2020/images/internal-arrow-1.png');
                top             : -7.5rem;
                left            : -2rem;
            }

            &.arrow-right-1:before {
                right: 25%;
                left : auto;
            }

            &.arrow-right-2:before {
                right: auto;
                left : 0;
            }

        }

        &.style-1 {
            &:before {
                height          : 2.8rem;
                width           : 1.6rem;
                background-image: url('/2020/images/internal-arrow-3.png');
                top             : calc(50% - 1.4rem);
                left            : -2rem;
            }
        }

        &.style-2 {
            &:before {
                height          : 2.2rem;
                width           : 1.2rem;
                background-image: url('/2020/images/internal-arrow-4.png');
                top             : .4rem;
                left            : -2rem;
            }

            @include media-breakpoint-down(sm) {
                margin-left: 15px;
            }
        }

        &.style-3 {
            &:before {
                height          : 3.2rem;
                width           : 1.6rem;
                background-image: url('/2020/images/internal-arrow-4.png');
                top             : .4rem;
                left            : -3.2rem;
            }
        }

        &.style-4 {
            &:before {
                height          : 3.2rem;
                width           : 6.9rem;
                background-image: url('/2020/images/internal-arrow-5.png');
                top             : -5rem;
                left            : -3rem;
            }
        }

        &.style-6 {
            &:before {
                height          : 7rem;
                width           : 3.3rem;
                background-image: url('/2020/images/internal-arrow-1.png');
                transform       : rotateY(180deg);
                top             : -7.5rem;
                left            : -2rem;
            }

            &.arrow-right-1:before {
                right: 0;
                left : auto;
            }


        }
    }

    .arrow-after {
        position: relative;

        &:after {
            content          : '';
            background-repeat: no-repeat;
            background-size  : contain;
            position         : absolute;

        }

        &.style-0 {
            &:after {
                height          : 7rem;
                width           : 3.3rem;
                background-image: url('/2020/images/internal-arrow-1.png');
                transform       : rotateY(180deg);
                top             : -7.5rem;
                right           : 5rem;
            }
        }

        &.style-1 {
            &:after {
                height          : 7rem;
                width           : 3.3rem;
                background-image: url('/2020/images/internal-arrow-1.png');
                transform       : rotateX(180deg);
                bottom          : -7.5rem;
                left            : -3rem;
            }
        }
    }

    .arrow-2 {
        background-image : url('/2020/images/internal-arrow-2.png');
        background-repeat: no-repeat;
        background-size  : contain;
        height           : 3.5rem;
        width            : 100%;
    }


    .arrow-3 {
        background-image : url('/2020/images/internal-arrow-6.png');
        background-repeat: no-repeat;
        background-size  : contain;
        height           : 3.5rem;
        width            : 100%;
    }

    .arrow-4 {
        background-image : url('/2020/images/internal-arrow-7.png');
        background-repeat: no-repeat;
        background-size  : contain;
        height           : 3.5rem;
        width            : 100%;
    }

    .arrow-5 {
        background-image : url('/2020/images/internal-arrow-9.png');
        background-repeat: no-repeat;
        background-size  : contain;
        height           : 3.5rem;
        width            : 100%;
    }

    .arrow-6 {
        background-image : url('/2020/images/internal-arrow-11.png');
        background-repeat: no-repeat;
        background-size  : contain;
        height           : 3.5rem;
        width            : 100%;
    }

    .right-box {
        border               : .3rem solid #000;
        margin-left          : 8rem;
        padding              : 3rem;
        text-decoration      : underline;
        text-decoration-color: rgba(0, 0, 0, .3);
        position             : relative;

        .arrow-before-3.style-3 {
            &:before {
                left: -3.2rem;
            }
        }

        .bottom-logo {
            background-image   : url(/2020/images/internal-logo-1.png);
            background-repeat  : no-repeat;
            width              : 20rem;
            height             : 24.5rem;
            background-size    : contain;
            background-position: center;
            position           : relative;
            margin-bottom      : -15rem;
            left               : calc(50% - 10rem);
            background-color   : #fff;
        }
    }


    .border-border-black {
        border-bottom: .3rem solid #000;
    }

    .border-top-green1 {
        border-top: .3rem solid $green1;
    }


    .fluid-image {
        padding : 0 !important;
        position: relative;

        .fluid-image-text {
            position     : absolute;
            bottom       : 0;
            right        : 0;
            padding-left : 15px;
            padding-right: 15px;
        }

        @include media-breakpoint-down(sm) {
            .fluid-image-text {
                position     : relative;
                width        : 100% !important;
                margin-top   : 2rem !important;
                padding-left : 5% !important;
                padding-right: 5% !important;

                p {
                    &:before {
                        content: none;
                    }

                    &:after {
                        content: none;
                    }
                }
            }
        }

        &.fluid-image-right {
            .img-container {
                margin-left: auto;
            }

            @include media-breakpoint-down(sm) {
                display       : flex;
                flex-direction: column-reverse;
            }
        }

    }

    .right-col-values {
        display       : flex;
        flex-direction: column;

        >div {
            padding-left: 2rem;
        }

        @include media-breakpoint-down(sm) {
            justify-content      : center;
            margin-top           : 4rem;
            display              : grid;
            grid-template-columns: repeat(auto-fit, 176px);


            >div {
                width: inherit;

                p {
                    &.fs-60 {
                        font-size: 4rem;
                    }

                    &.fs-30 {
                        font-size: 25rem;
                    }

                    &.fs-16 {
                        font-size: 1.4rem;
                    }
                }
            }
        }
    }

    &.president-letter {
        .president-letter-row-1 {
            .president-letter-text {
                width: 65vw;

                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }
        }

        .president-letter-center-scrolled {
            @include media-breakpoint-down(md) {
                margin-bottom: 10rem;

                .right-box {
                    margin: 0;
                }
            }
        }

        .president-letter-last-row {
            @include media-breakpoint-down(sm) {

                .arrow-before {
                    &:before {
                        content: none;
                    }
                }
            }
        }
    }


    &.business-operations {
        .business-operations-arrow-1-row {
            @include media-breakpoint-down(md) {

                .arrow-before {
                    &:before {
                        content: none;
                    }

                    &:after {
                        content          : '';
                        background-repeat: no-repeat;
                        background-size  : contain;
                        position         : absolute;
                        height           : 3.2rem;
                        width            : 6.9rem;
                        background-image : url('/2020/images/internal-arrow-5.png');
                        transform        : rotateY(180deg);
                        top              : -5rem;
                        right            : 6rem;
                    }

                }
            }

        }

        .right-col-values {

            >div {
                @include media-breakpoint-down(sm) {
                    border-top: .3rem solid $green1;
                }

                @include media-breakpoint-up(sm) {
                    &:first-child {
                        border-top: .3rem solid $green1;
                    }
                }
            }
        }

        .business-operations-img-2 {

            .fluid-image-text {
                position: relative;
            }
        }
    }

    &.our-company {
        .our-company-block-3 {
            @include media-breakpoint-down(sm) {
                margin-top: 5rem !important;
            }
        }

        .our-company-block-6 {
            @include media-breakpoint-down(sm) {
                p.arrow-after:after {
                    transform: rotate(180deg);
                    left     : 0;
                }
            }
        }

    }

    &.our-leadership {
        .our-leadership-legend {
            border        : .1rem solid #339d8d;
            padding       : 2.5rem;
            padding-bottom: 4rem;
            margin-bottom : 14rem;
            position      : relative;


            .square {
                height      : 1.5rem;
                min-width   : 1.5rem;
                margin-right: 1rem;

                &.type-1 {
                    background-color: #0e4681;
                }

                &.type-2 {
                    background-color: #30a4dc;
                }

                &.type-3 {
                    background-color: #00a59e;
                }

                &.type-4 {
                    background-color: #e5bc11;
                }
            }

            .bottom-border-arrow {
                position             : absolute;
                height               : 37px;
                width                : 40px;
                background-image     : url(/2020/images/internal-arrow-8.png);
                background-color     : #fff;
                background-size      : cover;
                background-repeat    : no-repeat;
                background-position-x: center;
                left                 : calc(50% - 2rem);
                bottom               : -20px;
            }
        }
    }

    &.financial-highlights {
        .financial-highlights-table-1 {

            td,
            thead th {
                text-align: center;
            }

            th {
                font-weight: normal !important;
            }

            thead {
                th {
                    position      : relative;
                    padding-bottom: 2rem;

                    &:after {
                        content          : '';
                        background-repeat: no-repeat;
                        background-size  : contain;
                        position         : absolute;
                        height           : .9rem;
                        width            : 1.8rem;
                        background-image : url('/2020/images/internal-arrow-10.png');
                        bottom           : .8rem;
                        left             : calc(50% - .9rem);
                    }
                }


            }
        }

        .financial-highlights-chart {
            .financial-highlights-chart-img {
                padding: 0 15px;
            }

            .financial-highlights-chart-text {
                padding: 0 15px;

                >div {
                    border : .2rem solid $light-blue4;
                    padding: 2rem;

                    &.yellow {
                        border: .2rem solid $yellow1;

                        >p:first-child {
                            color: $yellow1;
                        }

                        @include media-breakpoint-up(lg) {
                            margin-top: 180px;
                        }

                        @include media-breakpoint-down(md) {
                            margin-top: 115px;
                        }




                    }

                    &.blue4 {
                        border: .2rem solid $blue4;

                        >p:first-child {
                            color: $blue4;
                        }
                    }

                    p {
                        line-height: 1;
                    }
                }
            }

            @include media-breakpoint-down(sm) {
                .financial-highlights-chart-text {
                    width        : 100%;
                    margin-top   : 4rem;
                    margin-bottom: 4rem;
                }
            }
        }
    }

}