$blue        : #044f7e;
$light-blue  : #085f99;
$light-blue-1: #66bccd;

$blue1      : #0083a0;
$light-blue1: #1696af;

$light-blue2  : #0070aa;
$light-blue2-2: #3b9acd;

$blue3      : #0067a6;
$light-blue3: #0273b2;

$blue4      :#0871ab;
$light-blue4:#6ab5e3;

$light-blue5:#3da3cb;

$green      : #01795b;
$light-green: #479d76;

$green1      : #5aacc2;
$light-green1: #65b8cf;

$green2      : #349c8c;
$light-green2: #3fb3a1;

$green3      : #90b15a;
$light-green3: #a7cc6c;

$yellow      : #dabd59;
$light-yellow: #eccf6c;

$yellow1:#efbe34;

$grey: #f1efed;

@each $size in 12,
13,
14,
15,
16,
18,
20,
22,
24,
27,
28,
30,
32,
34,
36,
38,
45,
46,
48,
54,
60,
70,
90,
98,
136 {
    .fs-#{$size} {
        font-size: #{$size/10}rem;
    }
}

@for $i from 1 through 20 {
    .mt-#{$i}0 {
        margin-top: 1rem * $i !important;
    }

    .mt-#{$i}5 {
        margin-top: 1rem * ($i + .5) !important;
    }

    .mb-#{$i}0 {
        margin-bottom: 1rem * $i !important;
    }

    .mb-#{$i}5 {
        margin-bottom: 1rem * ($i + .5) !important;
    }

    .ml-#{$i}0 {
        margin-left: 1rem * $i !important;
    }

    .ml-#{$i}5 {
        margin-left: 1rem * ($i + .5) !important;
    }

    .mr-#{$i}0 {
        margin-right: 1rem * $i !important;
    }

    .mr-#{$i}5 {
        margin-right: 1rem * ($i + .5) !important;
    }

    .pt-#{$i}0 {
        padding-top: 1rem * $i !important;
    }

    .pt-#{$i}5 {
        padding-top: 1rem * ($i + .5) !important;
    }

    .pb-#{$i}0 {
        padding-bottom: 1rem * $i !important;
    }

    .pb-#{$i}5 {
        padding-bottom: 1rem * ($i + .5) !important;
    }

    .pl-#{$i}0 {
        padding-left: 1rem * $i !important;
    }

    .pl-#{$i}5 {
        padding-left: 1rem * ($i + .5) !important;
    }

    .pr-#{$i}0 {
        padding-right: 1rem * $i !important;
    }

    .pr-#{$i}5 {
        padding-right: 1rem * ($i + .5) !important;
    }
}

@for $i from 8 through 15 {
    .lh-#{$i} {
        line-height: $i/10 !important;
    }
}

@for $i from 1 through 20 {
    .vw-#{$i*5} {
        width: #{$i*5}vw !important;
    }

    .w-#{$i*5} {
        width: #{$i*5}% !important;
    }

    .mlw-#{$i*5} {
        margin-left: #{$i*5}% !important;
    }

    .mrw-#{$i*5} {
        margin-right: #{$i*5}% !important;
    }

    .plw-#{$i*5} {
        padding-left: #{$i*5}% !important;
    }

    .prw-#{$i*5} {
        padding-right: #{$i*5}% !important;
    }
}

.bg-blue {
    background-color: $blue;
}

.bg-green {
    background-color: $green;
}

.bg-green1 {
    background-color: $green1;
}

.bg-green2 {
    background-color: $green2;
}

.bg-green3 {
    background-color: $green3;
}

.bg-blue1 {
    background-color: $blue1;
}

.bg-blue4 {
    background-color: $blue4;
}

.bg-yellow {
    background-color: $yellow;
}

.bg-light-blue1 {
    background-color: $light-blue1;
}

.bg-light-blue2 {
    background-color: $light-blue2;
}

.bg-grey {
    background-color: $grey;
}



.color-blue1 {
    color: $blue1;
}

.color-green {
    color: $green;
}

.color-green1 {
    color: $green1;
}

.color-green2 {
    color: $green2;
}

.color-blue2 {
    color: $light-blue2;
}

.color-light-blue4 {
    color: $light-blue4;
}

.color-light-blue5 {
    color: $light-blue5;
}

.color-blue4 {
    color: $blue4;
}

.color-light-blue-1 {
    color: $light-blue-1;
}

.ls-01 {
    letter-spacing: 0.1rem;
}