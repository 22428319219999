@font-face {
    font-family: "NHaasGroteskDSPro-25Th";
    src        : url("../fonts/NHaasGroteskDSPro-25Th.woff") format("woff"),
        /* chrome firefox */
}

@font-face {
    font-family: "NHaasGroteskDSPro-45Lt";
    src        : url("../fonts/NHaasGroteskDSPro-45Lt.woff") format("woff"),
        /* chrome firefox */
}

@font-face {
    font-family: "NHaasGroteskDSPro-55Rg";
    src        : url("../fonts/NHaasGroteskDSPro-55Rg.eot");
    /* IE9*/
    src        : url("../fonts/NHaasGroteskDSPro-55Rg.eot") format("embedded-opentype"),
        /* IE6-IE8 */
        url("../fonts/NHaasGroteskDSPro-55Rg.woff2") format("woff2"),
        /* chrome firefox */
        url("../fonts/NHaasGroteskDSPro-55Rg.woff") format("woff"),
        /* chrome firefox */
        url("../fonts/NHaasGroteskDSPro-55Rg.ttf") format("truetype"),
        /* chrome firefox opera Safari, Android, iOS 4.2+*/
        url("../fonts/NHaasGroteskDSPro-55Rg.svg") format("svg");
    /* iOS 4.1- */
}

@font-face {
    font-family: "NHaasGroteskDSPro-56it";
    src        : url("../fonts/NHaasGroteskDSPro-56It.otf");
}

@font-face {
    font-family: "NHaasGroteskDSPro-65Md";
    src        : url("../fonts/NHaasGroteskDSPro-65Md.eot");
    /* IE9*/
    src        : url("../fonts/NHaasGroteskDSPro-65Md.eot") format("embedded-opentype"),
        /* IE6-IE8 */
        url("../fonts/NHaasGroteskDSPro-65Md.woff2") format("woff2"),
        /* chrome firefox */
        url("../fonts/NHaasGroteskDSPro-65Md.woff") format("woff"),
        /* chrome firefox */
        url("../fonts/NHaasGroteskDSPro-65Md.ttf") format("truetype"),
        /* chrome firefox opera Safari, Android, iOS 4.2+*/
        url("../fonts/NHaasGroteskDSPro-65Md.svg") format("svg");
    /* iOS 4.1- */
}

@font-face {
    font-family: "NHaasGroteskDSPro-66MdIt";
    src        : url("../fonts/NHaasGroteskDSPro-66MdIt.woff") format("woff"),
        /* chrome firefox */
}

@font-face {
    font-family: "NHaasGroteskDSPro-75Bd";
    src        : url("../fonts/NHaasGroteskDSPro-75Bd.eot");
    /* IE9*/
    src        : url("../fonts/NHaasGroteskDSPro-75Bd.eot") format("embedded-opentype"),
        /* IE6-IE8 */
        url("../fonts/NHaasGroteskDSPro-75Bd.woff2") format("woff2"),
        /* chrome firefox */
        url("../fonts/NHaasGroteskDSPro-75Bd.woff") format("woff"),
        /* chrome firefox */
        url("../fonts/NHaasGroteskDSPro-75Bd.ttf") format("truetype"),
        /* chrome firefox opera Safari, Android, iOS 4.2+*/
        url("../fonts/NHaasGroteskDSPro-75Bd.svg") format("svg");
    /* iOS 4.1- */
}

@font-face {
    font-family: "NHaasGroteskDSPro-95Blk";
    src        : url("../fonts/NHaasGroteskDSPro-95Blk.woff") format("woff"),
        /* chrome firefox */
}

.font-25th {
    font-family: "NHaasGroteskDSPro-25Th";
}

.font-45lt {
    font-family: "NHaasGroteskDSPro-45Lt";
}

.font-55rg {
    font-family: "NHaasGroteskDSPro-55Rg";
}

.font-56it {
    font-family: "NHaasGroteskDSPro-56it";
}

.font-65md {
    font-family: "NHaasGroteskDSPro-65Md";
}

.font-66mdit {
    font-family: "NHaasGroteskDSPro-66MdIt";
}

.font-75bd {
    font-family: "NHaasGroteskDSPro-75Bd";
}

.font-95blk {
    font-family: "NHaasGroteskDSPro-95Blk";
}

html {
    font-size: 8px !important;

    @media (max-width: 768px) and (orientation: landscape) {
        font-size: 4px !important;
    }

    @include media-breakpoint-up(lg) {
        font-size: 1.25vmin !important;
    }


    &.home {

        @media (min-width: 992px),
        (min-width: 768px) and (orientation: portrait) {
            font-size: 1.25vh !important;
        }

        @media (max-width: 767px) {
            font-size: 1.5vh !important;
        }
    }
}