.header {
    position: fixed;
    top     : 0;
    width   : 100%;
    height  : 6rem;
    z-index : 999;

    .logo {

        height          : 4rem;
        width           : 19.8rem;
        left            : 2rem;
        top             : 1.5rem;
        background-image: url('/2020/images/logo.png');
        background-size : cover;
        position        : absolute;
    }

    .menu {
        position: absolute;
        height  : auto;
        color   : white;
        right   : 2.5rem;
        top     : 3.5rem;

        #menu-open-btn {
            cursor: pointer;
        }
    }

    .menu-expanded {
        position: fixed;
        top     : 0;
        height  : 100%;
        width   : 100%;
        display : none;
        z-index : 999;
        color   : white;

        &.open {
            display: block;
        }

        .menu-expanded-bg {
            height          : 100%;
            background-color: rgba(0, 0, 0, .4);

            .menu-expanded-content {
                height          : 100%;
                width           : 70%;
                padding         : 2rem;
                margin-left     : 30vw;
                background-color: $blue3;
                overflow-x      : auto;

                &::-webkit-scrollbar {
                    display: none;
                }

                .menu-expanded-close {
                    text-align    : right;
                    margin-top    : 3rem;
                    padding-bottom: 2rem;
                    border-bottom : 0.1rem solid #fff;
                    cursor        : pointer;
                }

                .menu-expanded-items {
                    ul {
                        padding   : 0;
                        list-style: none;

                        li {
                            padding-top   : 1.8rem;
                            padding-bottom: .8rem;
                            border-bottom : .2rem solid $light-blue3;

                            a {
                                z-index : 9;
                                display : block;
                                position: relative;

                                p {
                                    line-height  : .9;
                                    margin-bottom: 0;

                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                }

                                &:after {
                                    content         : '';
                                    width           : 3.8em;
                                    height          : 3rem;
                                    background-image: url(/2020/images/menu-arrow.png);
                                    background-size : contain;
                                    display         : block;
                                    position        : absolute;
                                    top             : calc(50% - 2rem);
                                    right           : 0;
                                }
                            }

                            &:last-child {
                                border: none;
                            }
                        }
                    }
                }
            }
        }
    }
}