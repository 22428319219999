html,
body {
    .homepage {
        &::-webkit-scrollbar {
            display: none;
        }
    }

    overflow-x: hidden;
}


p {
    margin     : 0;
    line-height: 1;
}

a {
    text-transform: none;
    color         : inherit;

    &:hover {
        text-decoration: none;
        color          : inherit;
    }
}

.center-scrolled {
    height: 100%;

    .pin-spacer {
        margin: 0 !important;
    }
}

footer {
    .logo {
        width: 20rem;
    }
}