.homepage {
    height        : 100vh;
    display       : flex;
    flex-direction: row;
    overflow      : hidden;
    color         : #fff;

    section {
        height        : 100%;
        display       : flex;
        align-items   : center;
        padding-top   : 16rem;
        padding-bottom: 13.5rem;

        @include media-breakpoint-down(sm) {
            padding-top   : 10rem;
            padding-bottom: 5rem;
        }

        >div {
            height       : 100%;
            border-top   : .2rem solid $light-blue;
            border-bottom: .2rem solid $light-blue;
        }
    }

    .arrow-before {
        position: relative;

        &:before {
            content            : '';
            background-image   : url(/2020/images/arrow-type-1.png);
            background-position: center;
            background-size    : contain;
            background-repeat  : no-repeat;
            display            : block;
            height             : 2.2rem;
            width              : 1rem;
            position           : absolute;
            left               : -2rem;
            top                : calc(50% - 1.1rem);
        }
    }

    .section-read-more {
        .section-read-more-inner {
            position         : relative;
            width            : 100vw;
            background-repeat: no-repeat;

            .section-read-more-inner-arrow {
                position           : absolute;
                top                : 0;
                width              : 24.3rem;
                height             : 100%;
                margin-left        : 16rem;
                background-repeat  : no-repeat;
                background-size    : 100% 9%;
                background-position: 0 47.5%;
                display            : flex;
                align-items        : center;
                z-index            : 9;

                p {
                    margin-left  : 5rem;
                    margin-bottom: 11%;
                    color        : #fff;
                    border-bottom: .1rem solid rgba(255, 255, 255, .4);
                }
            }

            .section-read-more-inner-top {
                height  : 50%;
                position: relative;

                >div {
                    margin-left: 10rem;
                    position   : absolute;
                    bottom     : 20%;

                    p {
                        color      : #fff;
                        line-height: .9;
                    }
                }
            }

            .section-read-more-inner-bottom {
                height  : 50%;
                position: relative;

                >div {
                    margin-left: 25rem;
                    position   : absolute;
                    top        : 15%;
                    color      : #fff;

                    p.line-1 {
                        width: 40rem;
                    }
                }
            }
        }
    }

    .intro {
        .preloader {
            height          : 100vh;
            width           : 100vw;
            display         : flex;
            align-items     : center;
            justify-content : center;
            background-color: $blue;
            z-index         : 9;
            position        : fixed;
        }

        video {
            position  : fixed;
            right     : 0;
            bottom    : 0;
            object-fit: fill;
            width     : 100vw;
            height    : 100vh;
            z-index   : 9999;
        }

        .skip {
            position: absolute;
            bottom  : 4.5%;
            left    : 45%;
            width   : 10%;
            height  : 5%;
            cursor  : pointer;
            z-index : 10000;

            @media (orientation: portrait) {
                top  : 4%;
                right: 5%;
                left : auto;
                width: 30%;
            }

        }
    }

    .section-1 {
        .section-1-inner {
            display              : flex;
            background-image     : url('/2020/images/arrow-bg-blue.png');
            background-repeat    : no-repeat;
            background-position-x: 33rem;
            background-size      : cover;

            .section-1-inner-block-1 {
                width          : 100vw;
                padding-left   : 2.5rem;
                display        : flex;
                flex-direction : column;
                justify-content: center;

                .sectin-1-right-text {
                    width: 33rem;
                }

                .section-1-scroll {
                    margin-top: 3rem;
                    width     : 10rem;

                    img {
                        width: 3.2rem;
                    }

                    p {
                        margin-top: .5rem;
                    }
                }
            }
        }

        .section-1-inner-block-2 {
            margin-right: 11rem;
            display     : flex;
            align-items : center;

            .section-2-line-1 {
                height: calc(78vh - 31rem);
                width : 50vmax;

                iframe {
                    height: 100%;
                    width : 100%;
                }
            }
        }
    }

    .section-2 {
        .section-read-more-inner {
            border-top-color   : $light-blue1;
            border-bottom-color: $light-blue1;
            background-image   : url('/2020/images/arrow-bg-blue1.png');
            background-size    : 100% 9%;
            background-position: 0 47.5%;
            min-width          : 70rem;

            .section-read-more-inner-arrow {
                background-image: url('/2020/images/arrow-bg-center-light-blue1.png');
            }

            .section-read-more-inner-bottom {
                >div {
                    p.line-2 {
                        &:nth-child(2) {
                            margin-top: 1.5rem;
                        }
                    }
                }
            }
        }
    }

    .section-3 {
        .section-3-inner {
            display              : flex;
            background-image     : url('/2020/images/arrow-bg-blue-1.png');
            background-repeat    : no-repeat;
            background-size      : cover;
            width                : 180rem;
            background-position-x: -135rem;
            position             : relative;

            .section-3-left {
                margin-left: 13rem;
                margin-top : 6rem;
                position   : relative;

                .section-3-left-text {
                    display: flex;

                    p {
                        &:first-child {
                            line-height: 8rem;

                            span {
                                vertical-align: middle;
                            }

                            &.arrow-after {
                                position: relative;

                                &:after {
                                    content            : '';
                                    background-image   : url(/2020/images/arrow-type-3.png);
                                    background-position: center;
                                    background-size    : contain;
                                    background-repeat  : no-repeat;
                                    display            : block;
                                    height             : 6rem;
                                    width              : 3rem;
                                    position           : absolute;
                                    left               : calc(100% - 4rem);
                                    top                : calc(100% + 2rem);
                                }
                            }
                        }

                        &:last-child {
                            margin-left: 1rem;
                            line-height: 0.9;
                        }
                    }
                }

                .section-3-left-img {
                    background-size: contain;
                    height         : 29rem;
                    width          : 43.5rem;
                    position       : absolute;
                    bottom         : 0;
                    left           : 9.5rem;
                }
            }

            .section-3-middle {
                position: absolute;
                top     : 28rem;
                left    : 75em;

                .section-3-line-2 {
                    display: flex;

                    .section-3-line-2-img {
                        img {
                            height: 12rem;
                        }
                    }

                    .section-3-line-2-arrow {
                        margin: 0 1rem;
                    }

                    .section-3-line-2-text {
                        color         : #fff;
                        letter-spacing: .1rem;
                        line-height   : 1.1;
                        white-space   : nowrap;
                    }
                }
            }

            .section-3-right {
                position: absolute;
                left    : 89rem;

                .section-3-right-text {
                    display : flex;
                    position: absolute;
                    top     : 28rem;
                    left    : 55rem;

                    p {
                        &:first-child {
                            line-height: 8rem;

                            span {
                                vertical-align: middle;
                            }

                            &.arrow-after {
                                position: relative;

                                &:after {
                                    content            : '';
                                    background-image   : url(/2020/images/arrow-type-3.png);
                                    background-position: center;
                                    background-size    : contain;
                                    background-repeat  : no-repeat;
                                    display            : block;
                                    height             : 6rem;
                                    width              : 3rem;
                                    position           : absolute;
                                    left               : -3.5rem;
                                    top                : -5rem;
                                }
                            }
                        }

                        &:last-child {
                            margin-left: 1rem;
                            width      : max-content;
                            line-height: 0.9;
                        }
                    }
                }

                .section-3-right-img {
                    background-size: contain;
                    height         : 20rem;
                    width          : 60rem;
                    position       : absolute;
                    top            : 0;
                }
            }
        }
    }

    .section-4 {
        .section-read-more-inner {
            border-top-color   : $light-blue2-2;
            border-bottom-color: $light-blue2-2;
            background-image   : url('/2020/images/arrow-bg-light-blue2.png');
            background-size    : 1000% 100%;
            background-position: 0 47.5%;
            min-width          : 70rem;

            .section-read-more-inner-arrow {
                background-image: url('/2020/images/arrow-bg-center-light-blue2.png');
            }

            .section-4-inner-top {
                position           : absolute;
                top                : 0;
                width              : 24.3rem;
                height             : 100%;
                margin-left        : 16rem;
                background-image   : url('/2020/images/arrow-bg-6.png');
                background-repeat  : no-repeat;
                background-size    : 100% 9%;
                background-position: 0 47.5%;
                display            : flex;
                align-items        : center;
            }
        }
    }

    .section-5 {
        .section-5-inner {
            display              : flex;
            background-image     : url('/2020/images/arrow-bg-blue-1.png');
            background-repeat    : no-repeat;
            background-size      : cover;
            background-position-x: -155rem;
            min-width            : 195rem;
            position             : relative;


            .section-5-inner-block-1 {
                margin-left: 10rem;

                .section-5-line-1 {
                    height: calc(78vh - 31rem);

                    iframe {
                        height: 100%;
                        width : 100%;
                    }
                }

                // .section-5-line-2 {
                //     display    : flex;
                //     margin-left: 11rem;
                //     margin-top : 1.5rem;

                //     .section-5-line-2-img {
                //         img {
                //             height: 12rem;
                //         }
                //     }

                //     .section-5-line-2-arrow {
                //         margin: 0 1rem;
                //     }

                //     .section-5-line-2-text {
                //         color         : #fff;
                //         letter-spacing: .1rem;
                //         line-height   : 1.1;
                //         white-space   : nowrap;
                //     }
                // }
            }

            .section-5-left {
                position: relative;

                .section-5-left-top,
                .section-5-left-bottom {
                    position      : absolute;
                    width         : max-content;
                    top           : 6rem;
                    left          : 13rem;
                    letter-spacing: .1rem;
                    display       : flex;
                    align-items   : center;

                    p:first-child:before {
                        content            : '';
                        background-image   : url(/2020/images/arrow-type-1.png);
                        background-position: center;
                        background-size    : contain;
                        background-repeat  : no-repeat;
                        display            : block;
                        height             : 3.3rem;
                        width              : 1.8rem;
                        position           : absolute;
                        left               : -2.5rem;
                        top                : 6.9rem;
                    }
                }

                .section-5-left-bottom {
                    top    : 29rem;
                    left   : 27rem;
                    display: block;
                }
            }

            .section-5-center {
                position: absolute;
                left    : 70rem;
                height  : 100%;

                .section-5-center-text {
                    display : flex;
                    position: absolute;
                    width   : max-content;
                    left    : 33.5rem;
                    top     : 5rem;

                    p {
                        letter-spacing: 1px;
                    }

                    &::before {
                        content            : '';
                        background-image   : url(/2020/images/arrow-type-4.png);
                        background-position: center;
                        background-size    : contain;
                        background-repeat  : no-repeat;
                        display            : block;
                        height             : 6rem;
                        width              : 3rem;
                        position           : absolute;
                        left               : -4.5rem;
                        bottom             : -5rem;
                    }
                }

                .section-5-center-video {
                    height  : 32.2rem;
                    width   : 56.2rem;
                    position: absolute;
                    bottom  : 0;
                    left    : 11.5rem;

                    img {
                        height: 100%;
                        width : 100%;
                    }
                }
            }

            .section-5-right {
                position: absolute;
                width   : max-content;
                top     : 17.7rem;
                left    : 150.5rem;

                p:first-child:before {
                    content            : '';
                    background-image   : url(/2020/images/arrow-type-1.png);
                    background-position: center;
                    background-size    : contain;
                    background-repeat  : no-repeat;
                    display            : block;
                    height             : 3.3rem;
                    width              : 1.8rem;
                    position           : absolute;
                    left               : -2.5rem;
                    top                : .6rem;
                }
            }
        }

    }

    .section-6 {
        .section-read-more-inner {
            border-top-color   : $light-green;
            border-bottom-color: $light-green;
            background-image   : url('/2020/images/arrow-bg-green.png');
            background-size    : 1000% 100%;
            background-position: 0 47.5%;
            min-width          : 70rem;

            .section-read-more-inner-arrow {
                background-image: url('/2020/images/arrow-bg-center-green.png');
            }
        }
    }

    .section-7 {
        .section-7-inner {
            display              : flex;
            background-image     : url('/2020/images/arrow-bg-blue-1.png');
            background-repeat    : no-repeat;
            background-size      : cover;
            width                : 180rem;
            background-position-x: -117rem;
            width                : 100vw;
            min-width            : 120rem;
            position             : relative;

            .section-7-left {
                .section-7-left-text {
                    display : flex;
                    position: absolute;
                    width   : max-content;
                    left    : 11rem;
                    top     : 8rem;

                    p {
                        letter-spacing: 1px;
                        line-height   : 1.1;
                    }

                    &::before {
                        content            : '';
                        background-image   : url(/2020/images/arrow-type-2.png);
                        background-position: center;
                        background-size    : contain;
                        background-repeat  : no-repeat;
                        display            : block;
                        height             : 3rem;
                        width              : 5rem;
                        position           : absolute;
                        left               : -6rem;
                        top                : -0.2rem;
                    }
                }
            }

            .section-7-right {
                margin-left: 57rem;
                position   : relative;

                .section-7-right-img {
                    background-size: contain;
                    height         : 31.2rem;
                    width          : 38rem;
                    position       : absolute;
                    top            : 0;
                }

                .section-7-right-text {
                    display : flex;
                    position: absolute;
                    bottom  : 6rem;
                    left    : -18rem;

                    p {
                        width      : max-content;
                        line-height: 1.1;
                    }

                    &:before {
                        content            : '';
                        background-image   : url(/2020/images/arrow-type-5.png);
                        background-position: center;
                        background-size    : contain;
                        background-repeat  : no-repeat;
                        display            : block;
                        height             : 6rem;
                        width              : 3rem;
                        position           : absolute;
                        right              : -4.5rem;
                        top                : -4rem;
                    }
                }
            }
        }

    }

    .section-8 {
        .section-read-more-inner {
            min-width          : 111rem;
            border-top-color   : $light-green1;
            border-bottom-color: $light-green1;
            background-image   : url('/2020/images/arrow-bg-green1.png');
            background-size    : 1000% 100%;
            background-position: 0 47.5%;

            .section-read-more-inner-arrow {
                background-image: url('/2020/images/arrow-bg-center-green1.png');
            }
        }

        .section-8-inner-right {
            >div {
                position: absolute;
                top     : 7rem;
                left    : 48rem;

                iframe {
                    width : 60rem;
                    height: 34rem;
                }
            }
        }
    }

    // .section-9 {
    //     .section-9-inner {
    //         display              : flex;
    //         background-image     : url('/2020/images/arrow-bg-blue-1.png');
    //         background-repeat    : no-repeat;
    //         background-size      : cover;
    //         width                : 180rem;
    //         background-position-x: -99rem;
    //         width                : 100vw;
    //         min-width            : 120rem;
    //         position             : relative;
    //         min-width            : 130rem;

    //         .section-9-left {
    //             .section-9-left-img {
    //                 position: absolute;
    //                 left    : 17rem;
    //                 height  : 33rem;
    //                 width   : 49.5rem;

    //                 img {
    //                     height: 100%;
    //                 }
    //             }

    //             .section-9-left-text {
    //                 position: absolute;
    //                 width   : max-content;
    //                 left    : 24rem;
    //                 top     : 36rem;

    //                 p {
    //                     letter-spacing: 1px;
    //                     line-height   : 1.1;
    //                 }

    //                 &::before {
    //                     content            : '';
    //                     background-image   : url(/2020/images/arrow-type-2.png);
    //                     background-position: center;
    //                     background-size    : contain;
    //                     background-repeat  : no-repeat;
    //                     display            : block;
    //                     height             : 3rem;
    //                     width              : 5rem;
    //                     position           : absolute;
    //                     left               : -6rem;
    //                     top                : -0.2rem;
    //                 }
    //             }
    //         }

    //         .section-9-right {
    //             position      : absolute;
    //             width         : max-content;
    //             top           : 6rem;
    //             left          : 83rem;
    //             letter-spacing: .1rem;

    //             p:first-child:before {
    //                 content            : '';
    //                 background-image   : url(/2020/images/arrow-type-1.png);
    //                 background-position: center;
    //                 background-size    : contain;
    //                 background-repeat  : no-repeat;
    //                 display            : block;
    //                 height             : 3.3rem;
    //                 width              : 1.8rem;
    //                 position           : absolute;
    //                 left               : -2.5rem;
    //                 top                : 6.9rem;
    //             }
    //         }
    //     }

    // }

    .section-10 {
        .section-read-more-inner {
            border-top-color   : $light-green2;
            border-bottom-color: $light-green2;
            background-image   : url('/2020/images/arrow-bg-green2.png');
            background-size    : 1000% 100%;
            background-position: 0 47.5%;
            min-width          : 70rem;

            .section-read-more-inner-arrow {
                background-image: url('/2020/images/arrow-bg-center-green2.png');
            }

            .section-read-more-inner-bottom {
                div {
                    top: 10%;

                    p {
                        line-height: .9;
                    }
                }

            }
        }
    }

    .section-11 {
        .section-11-inner {
            display              : flex;
            background-image     : url('/2020/images/arrow-bg-blue-1.png');
            background-repeat    : no-repeat;
            background-size      : cover;
            background-position-x: -86rem;
            width                : 100vw;
            min-width            : 145rem;
            position             : relative;

            .section-11-left {
                .section-11-left-text {
                    display : flex;
                    position: absolute;
                    width   : max-content;
                    left    : 39rem;
                    top     : 5rem;

                    p {
                        letter-spacing: 1px;
                    }

                    &::before {
                        content            : '';
                        background-image   : url(/2020/images/arrow-type-4.png);
                        background-position: center;
                        background-size    : contain;
                        background-repeat  : no-repeat;
                        display            : block;
                        height             : 6rem;
                        width              : 3rem;
                        position           : absolute;
                        left               : -4.5rem;
                        bottom             : -1rem;
                    }
                }

                .section-11-left-img {
                    background-size: contain;
                    height         : 32.2rem;
                    width          : 48.2rem;
                    position       : absolute;
                    bottom         : 0;
                    left           : 11.5rem;
                }
            }

            .section-11-right {
                position: absolute;
                width   : max-content;
                top     : 24rem;
                left    : 83rem;

                p:first-child {
                    line-height: 0.85;

                    &:before {
                        content            : '';
                        background-image   : url(/2020/images/arrow-type-1.png);
                        background-position: center;
                        background-size    : contain;
                        background-repeat  : no-repeat;
                        display            : block;
                        height             : 3.3rem;
                        width              : 1.8rem;
                        position           : absolute;
                        left               : -2.5rem;
                        top                : 5rem;
                    }
                }

                p:last-child {
                    margin-left: 20px;
                }
            }
        }

    }

    .section-12 {
        .section-read-more-inner {
            border-top-color   : $light-green3;
            border-bottom-color: $light-green3;
            background-image   : url('/2020/images/arrow-bg-green3.png');
            background-size    : 1000% 100%;
            background-position: 0 47.5%;
            min-width          : 145rem;

            .section-read-more-inner-arrow {
                background-image: url('/2020/images/arrow-bg-center-green3.png');
            }

            .section-read-more-inner-bottom {
                .section-12-bottom-img {
                    height         : 15rem;
                    width          : 14.8rem;
                    background-size: contain;
                    top            : 5rem;
                    left           : 21rem;
                    margin         : 0;
                }

            }

            .section-12-iframe {
                position: absolute;
                width   : 60rem;
                height  : 34rem;
                top     : 5rem;
                left    : 75rem;
                z-index : 9;
            }
        }
    }

    // .section-13 {
    //     .section-13-inner {
    //         display          : flex;
    //         background-image : url('/2020/images/arrow-bg-blue-1.png');
    //         background-repeat: no-repeat;
    //         background-size  : cover;
    //         width            : 100vw;
    //         position         : relative;
    //         min-width        : 85rem;

    //         .section-13-iframe {
    //             position: absolute;
    //             top     : 8rem;
    //             left    : 16rem;
    //             height  : 33rem;
    //             width   : 59rem;
    //         }
    //     }

    // }

    .section-14 {
        .section-read-more-inner {
            border-top-color   : $light-yellow;
            border-bottom-color: $light-yellow;
            background-image   : url('/2020/images/arrow-bg-yellow.png');
            background-size    : 100% 9%;
            background-position: 0 47.5%;
            min-width          : 70rem;

            .section-read-more-inner-arrow {
                background-image: url('/2020/images/arrow-bg-center-yellow.png');
            }

            .section-read-more-inner-bottom {
                z-index: 9;

                .section-14-bottom-list {
                    list-style: none;
                    padding   : 0;
                    position  : absolute;
                    top       : -2.2rem;
                    left      : 24rem;

                    li {
                        margin-bottom: 2.5rem;

                        a {
                            display      : inline;
                            border-bottom: .1rem solid rgba(255, 255, 255, .4);
                        }

                        &:first-child {
                            margin-bottom: 3.5rem;
                        }

                        &:before {
                            content         : '';
                            background-image: url(/2020/images/section-14-img-1.png);
                            background-size : contain;
                            height          : 2.1rem;
                            width           : 1.9rem;
                            position        : absolute;
                            left            : -3rem;
                        }
                    }
                }

            }
        }
    }




}